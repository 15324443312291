import React from 'react';

const ProprtyaiSpinner: React.FC = () => {
  const css = `
    #eWKMpATk4Bn5_to {
      animation: eWKMpATk4Bn5_to__to 4000ms linear infinite normal forwards;
    }
    @keyframes eWKMpATk4Bn5_to__to {
      0% {
        transform: translate(200px,190px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      25% {
        transform: translate(200px,250px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      50% {
        transform: translate(200px,190px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      75% {
        transform: translate(200px,250px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      100% {
        transform: translate(200px,190px);
      }
    }

    #eWKMpATk4Bn9_to {
      animation: eWKMpATk4Bn9_to__to 4000ms linear infinite normal forwards;
    }
    @keyframes eWKMpATk4Bn9_to__to {
      0% {
        transform: translate(300px,305.24px);
        animation-timing-function: cubic-bezier(0.68,-0.55,0.265,1.55);
      }
      12.5% {
        transform: translate(300px,245.24px);
        animation-timing-function: cubic-bezier(0.68,-0.55,0.265,1.55);
      }
      25% {
        transform: translate(300px,305.24px);
        animation-timing-function: cubic-bezier(0.68,-0.55,0.265,1.55);
      }
      37.5% {
        transform: translate(300px,245.24px);
        animation-timing-function: cubic-bezier(0.68,-0.55,0.265,1.55);
      }
      50% {
        transform: translate(300px,305.24px);
        animation-timing-function: cubic-bezier(0.68,-0.55,0.265,1.55);
      }
      62.5% {
        transform: translate(300px,245.24px);
        animation-timing-function: cubic-bezier(0.68,-0.55,0.265,1.55);
      }
      75% {
        transform: translate(300px,305.24px);
        animation-timing-function: cubic-bezier(0.68,-0.55,0.265,1.55);
      }
      87.5% {
        transform: translate(300px,245.24px);
        animation-timing-function: cubic-bezier(0.68,-0.55,0.265,1.55);
      }
      100% {
        transform: translate(300px,305.24px);
      }
    }

    #eWKMpATk4Bn13_to {
      animation: eWKMpATk4Bn13_to__to 4000ms linear infinite normal forwards;
    }
    @keyframes eWKMpATk4Bn13_to__to {
      0% {
        transform: translate(100px,285px);
        animation-timing-function: cubic-bezier(0.68,0.02,0.875,0.2);
      }
      12.5% {
        transform: translate(100px,325px);
        animation-timing-function: cubic-bezier(0.68,0.02,0.875,0.2);
      }
      25% {
        transform: translate(100px,285px);
        animation-timing-function: cubic-bezier(0.68,0.02,0.875,0.2);
      }
      37.5% {
        transform: translate(100px,325px);
        animation-timing-function: cubic-bezier(0.68,0.02,0.875,0.2);
      }
      50% {
        transform: translate(100px,285px);
        animation-timing-function: cubic-bezier(0.68,0.02,0.875,0.2);
      }
      62.5% {
        transform: translate(100px,325px);
        animation-timing-function: cubic-bezier(0.68,0.02,0.875,0.2);
      }
      75% {
        transform: translate(100px,285px);
        animation-timing-function: cubic-bezier(0.68,0.02,0.875,0.2);
      }
      87.5% {
        transform: translate(100px,325px);
        animation-timing-function: cubic-bezier(0.68,0.02,0.875,0.2);
      }
      100% {
        transform: translate(100px,285px);
      }
    }

    #eWKMpATk4Bn17_to {
      animation: eWKMpATk4Bn17_to__to 4000ms linear infinite normal forwards;
    }
    @keyframes eWKMpATk4Bn17_to__to {
      0% {
        transform: translate(200px,365px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      6.25% {
        transform: translate(200px,385px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      12.5% {
        transform: translate(200px,365px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      18.75% {
        transform: translate(200px,385px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      25% {
        transform: translate(200px,365px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      31.25% {
        transform: translate(200px,385px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      37.5% {
        transform: translate(200px,365px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      43.75% {
        transform: translate(200px,385px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      50% {
        transform: translate(200px,365px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      56.25% {
        transform: translate(200px,385px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      62.5% {
        transform: translate(200px,365px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      68.75% {
        transform: translate(200px,385px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      75% {
        transform: translate(200px,365px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      81.25% {
        transform: translate(200px,385px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      87.5% {
        transform: translate(200px,365px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      93.75% {
        transform: translate(200px,385px);
        animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
      }
      100% {
        transform: translate(200px,365px);
      }
    }
  `;
  return (
    <svg
      id="eWKMpATk4Bn1"
      viewBox="0 0 400 440"
      width="400"
      height="440"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <style>{css}</style>
      <g clipPath="url(#eWKMpATk4Bn22)">
        <g>
          <g id="eWKMpATk4Bn5_to" transform="translate(200,190)">
            <g transform="translate(-200,-220)">
              <path d="M300,50L200,100v340h100v-390Z" fill="#86b2a5" />
              <path d="M100,50l100,50v340h-100v-390Z" fill="#187770" />
              <path d="M200,0L300,50L200,100L100,50L200,0Z" fill="var(--mui-palette-background-default)" />
            </g>
          </g>
          <g id="eWKMpATk4Bn9_to" transform="translate(300,305.24)">
            <g transform="translate(-300,-275)">
              <path d="M400,160L300,210v230h100v-280Z" fill="#86b2a5" />
              <path d="M200,160l100,50v230h-100v-280Z" fill="#187770" />
              <path d="M300,110l100,50-100,50-100-50l100-50Z" fill="var(--mui-palette-background-default)" />
            </g>
          </g>
          <g id="eWKMpATk4Bn13_to" transform="translate(100,285)">
            <g transform="translate(-100,-305)">
              <path d="M200,220L100,270v170h100v-220Z" fill="#86b2a5" />
              <path d="M0,220l100,50v170h-100v-220Z" fill="#187770" />
              <path d="M100,170l100,50-100,50L0,220l100-50Z" fill="var(--mui-palette-background-default)" />
            </g>
          </g>
          <g id="eWKMpATk4Bn17_to" transform="translate(200,365)">
            <g transform="translate(-200,-375)">
              <path d="M300,330L200,380v90l100-50v-90Z" fill="#86b2a5" />
              <path d="M100,330l100,50v90L100,420v-90Z" fill="#187770" />
              <path d="M200,280l100,50-100,50-100-50l100-50Z" fill="var(--mui-palette-background-default)" />
            </g>
          </g>
          <path d="M0,440v-100L200,440L400,340v100h-200-200Z" fill="var(--mui-palette-background-default)" />
        </g>
        <clipPath id="eWKMpATk4Bn22">
          <rect width="400" height="440" rx="0" ry="0" fill="#fff" />
        </clipPath>
      </g>
    </svg>
  );
};

export default ProprtyaiSpinner;
