'use client';
import { Box, Container } from '@mui/material';

import ProprtyaiSpinner from './ProprtyaiSpinner';
import ProprtyaiSpinnerSlow from './ProprtyaiSpinnerSlow';

export const LoadingComponent = ({ showSlow, dontCenter }: { showSlow?: boolean; dontCenter?: boolean }) => {
  const spinner = showSlow ? <ProprtyaiSpinnerSlow /> : <ProprtyaiSpinner />;

  return (
    <Container sx={dontCenter ? {} : { height: '100vh' }}>
      <Box display={'flex'} height={'100%'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
        <Box display={'flex'} sx={{ maxWidth: '100px' }}>
          {spinner}
        </Box>
      </Box>
    </Container>
  );
};
