import React from 'react';

const ProprtyaiSpinnerSlow: React.FC = () => {
  const css = `
    #emTaNZP3sWR5_to {
      animation: emTaNZP3sWR5_to__to 10000ms linear 1 normal forwards;
    }
    @keyframes emTaNZP3sWR5_to__to {
      0% {
        transform: translate(200px,460px);
        animation-timing-function: cubic-bezier(0.19,1,0.22,1);
      }
      56% {
        transform: translate(200px,220px);
      }
      100% {
        transform: translate(200px,220px);
      }
    }
    #emTaNZP3sWR9_to {
      animation: emTaNZP3sWR9_to__to 10000ms linear 1 normal forwards;
    }
    @keyframes emTaNZP3sWR9_to__to {
      0% {
        transform: translate(300px,455px);
      }
      33% {
        transform: translate(300px,455px);
        animation-timing-function: cubic-bezier(0.19,1,0.22,1);
      }
      80% {
        transform: translate(300px,275px);
      }
      100% {
        transform: translate(300px,275px);
      }
    }
    #emTaNZP3sWR13_to {
      animation: emTaNZP3sWR13_to__to 10000ms linear 1 normal forwards;
    }
    @keyframes emTaNZP3sWR13_to__to {
      0% {
        transform: translate(100px,425px);
      }
      65% {
        transform: translate(100px,425px);
        animation-timing-function: cubic-bezier(0.19,1,0.22,1);
      }
      95% {
        transform: translate(100px,305px);
      }
      100% {
        transform: translate(100px,305px);
      }
    }
    #emTaNZP3sWR17_to {
      animation: emTaNZP3sWR17_to__to 10000ms linear 1 normal forwards;
    }
    @keyframes emTaNZP3sWR17_to__to {
      0% {
        transform: translate(200px,435px);
      }
      86% {
        transform: translate(200px,435px);
        animation-timing-function: cubic-bezier(0.19,1,0.22,1);
      }
      100% {
        transform: translate(200px,375px);
      }
    }
  `;
  return (
    <svg
      id="emTaNZP3sWR1"
      viewBox="0 0 400 440"
      width="400"
      height="440"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <style>{css}</style>
      <g clipPath="url(#eWKMpATk4Bn22)">
        <g>
          <g id="emTaNZP3sWR5_to" transform="translate(200,460)">
            <g transform="translate(-200,-220)">
              <path d="M300,50L200,100v340h100v-390Z" fill="#86b2a5" />
              <path d="M100,50l100,50v340h-100v-390Z" fill="#187770" />
              <path d="M200,0L300,50L200,100L100,50L200,0Z" fill="var(--mui-palette-background-default)" />
            </g>
          </g>
          <g id="emTaNZP3sWR9_to" transform="translate(300,455)">
            <g transform="translate(-300,-275)">
              <path d="M400,160L300,210v230h100v-280Z" fill="#86b2a5" />
              <path d="M200,160l100,50v230h-100v-280Z" fill="#187770" />
              <path d="M300,110l100,50-100,50-100-50l100-50Z" fill="var(--mui-palette-background-default)" />
            </g>
          </g>
          <g id="emTaNZP3sWR13_to" transform="translate(100,425)">
            <g transform="translate(-100,-305)">
              <path d="M200,220L100,270v170h100v-220Z" fill="#86b2a5" />
              <path d="M0,220l100,50v170h-100v-220Z" fill="#187770" />
              <path d="M100,170l100,50-100,50L0,220l100-50Z" fill="var(--mui-palette-background-default)" />
            </g>
          </g>
          <g id="emTaNZP3sWR17_to" transform="translate(200,435)">
            <g transform="translate(-200,-375)">
              <path d="M300,330L200,380v90l100-50v-90Z" fill="#86b2a5" />
              <path d="M100,330l100,50v90L100,420v-90Z" fill="#187770" />
              <path d="M200,280l100,50-100,50-100-50l100-50Z" fill="var(--mui-palette-background-default)" />
            </g>
          </g>
          <path d="M0,440v-100L200,440L400,340v100h-200-200Z" fill="var(--mui-palette-background-default)" />
        </g>
        <clipPath id="eWKMpATk4Bn22">
          <rect width="400" height="440" rx="0" ry="0" fill="#fff" />
        </clipPath>
      </g>
    </svg>
  );
};

export default ProprtyaiSpinnerSlow;
